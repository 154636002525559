<template>
  <table>
    <tr>
      <th rowspan="2" style="border-right: 1px solid #e0e0e0; width: 60%">
        <h3 class="name">
          <v-icon>mdi-heart</v-icon>
          {{ $t("common.heartRate") }}:
        </h3>
        <h1 class="value">
          {{ value.pulse || "---" }}<span class="sub_text">bpm</span>
        </h1>
      </th>
      <td class="pr-2 pl-1">
        <h3 class="name right">{{ $t("common.sys") }}:</h3>
      </td>
      <td>
        <h1 class="value sub">{{ value.sys || "---" }}</h1>
      </td>
    </tr>
    <tr>
      <td class="pr-2 pl-1">
        <h3 class="name right">{{ $t("common.dias") }}:</h3>
      </td>
      <td>
        <h1 class="value sub">{{ value.dia || "---" }}</h1>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>
