<template>
  <v-container class="pa-0 ma-0">
    <div style="text-align: center" v-if="loading" class="mt-2">
      <v-progress-circular indeterminate color="#fefefe"></v-progress-circular>
    </div>
    <div
      v-else
      class="content_card"
      style="
        min-height: calc(100vh - 188px);
        display: block;
        padding-bottom: 0px;
      "
    >
      <div class="ble_result_container">
        <h3 class="pl-7 mb-1" style="color: #e0e0e0; margin-top: 12px">
          {{ $t("common.profile") }}:
          {{ (profile && profile.fullName) || $t("common.nodata") }}
        </h3>
        <div class="select-custom fixed" style="top: 20px">
          <v-select
            v-model="datesCount"
            filled
            outlined
            required
            hide-details
            :items="datesCountOptions"
            @change="render"
            dense
          ></v-select>
        </div>
        <div class="table mt-5">
          <highcharts :options="chartLineOptions"></highcharts>
          <stats-box-2 v-model="stats" />
        </div>
        <div class="ble_result_container">
          <stats-box v-model="avg" />
        </div>
        <div class="table">
          <highcharts :options="chartPieOptions"></highcharts>
        </div>
        <div class="table">
          <highcharts :options="chartBubbleOptions"></highcharts>
        </div>
        <div class="table">
          <highcharts :options="chartHeatmapOptions"></highcharts>
        </div>
        <v-data-table
          :headers="headers"
          :items="datapointsTable"
          class="elevation-1 -break"
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.pulse="{ item }">
            <v-icon style="font-size: 1rem">mdi-heart-outline</v-icon>
            {{ item.pulse }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.status="{ item }">
            <v-badge
              style="font-size: 10px"
              :color="
                item.status == 1
                  ? 'red'
                  : item.status == 2
                  ? 'orange'
                  : item.status == 3
                  ? 'green'
                  : 'cyan'
              "
            ></v-badge>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import {
  statsLineChartOptions,
  statsPieChartOptions,
  bubbleChartOptions,
  heatmapChartOptions,
} from "@/components/ChartOptions";
import StatsBox from "@/components/StatsBox.vue";
import StatsBox2 from "@/components/StatsBox2.vue";
import axios from "axios";

export default {
  components: {
    StatsBox,
    StatsBox2,
  },
  computed: {
    datesCountOptions() {
      return [
        { text: `3 ${this.$t("common.date")}`, value: 3 },
        { text: `7 ${this.$t("common.date")}`, value: 7 },
        { text: `30 ${this.$t("common.date")}`, value: 30 },
        { text: `90 ${this.$t("common.date")}`, value: 90 },
        { text: `${this.$t("common.allData")}`, value: null },
      ];
    },
  },
  data() {
    return {
      datesCount: 30,
      tabs: null,
      loading: true,
      items: [],
      patientId: null,
      profile: null,
      datapoints: [],
      chartLineOptions: {},
      chartPieOptions: {},
      chartBubbleOptions: {},
      chartHeatmapOptions: {},
      episodes: null,
      avg: {
        pulse: "---",
        sys: "---",
        dia: "---",
      },
      stats: {
        low: 0,
        normal: 0,
        high: 0,
        veryHigh: 0,
      },
      datapointsTable: [],
      headers: [
        { text: "#", value: "ind", sortable: false },
        { text: this.$t("common.datetime"), value: "date", sortable: false },
        { text: this.$t("common.sys"), value: "sys", sortable: false },
        { text: this.$t("common.dias"), value: "dia", sortable: false },
        { text: this.$t("common.heartRate"), value: "pulse", sortable: false },
        { text: "", value: "status", sortable: false },
      ],
    };
  },
  async mounted() {
    this.render();
    this.$store.commit("Scanner/SET_IS_MEASURING", false);
  },
  methods: {
    async render() {
      var patientPhone = this.$route.params.id || null;
      if (!patientPhone) return;
      axios
        .get(
          `https://us-central1-sandrasoft-8fe2b.cloudfunctions.net/publicProfile/?phone=${patientPhone}`
        )
        .then((response) => {
          // load data user
          this.profile = response.data.userObj;
          this.patientId = this.profile.patientId;
          this.episodes = response.data.episodes;
          this.getEpisodes();
          // load data point
          var result = [];
          response.data.arrayDataPoints.forEach((pack) => {
            result = pack.concat(result);
          });
          this.datapoints = result.filter(
            (i) => i.sys && i.dia && i.pulse && i.date
          );
          if (this.datesCount) {
            this.datapoints = this.datapoints.filter((p) =>
              moment(p.date).isAfter(moment().subtract(this.datesCount, "day"))
            );
          }
          this.datapoints = this.sortDatapoints(this.datapoints);
          this.renderStatsLineChart();
          this.calculateAvg();
          this.calculateStats();
          this.renderStatsPieChart();
          this.renderDataTable();
          this.renderBubbleChart();
          this.renderHeatmapChart();
        });
    },
    async renderDataTable() {
      this.datapointsTable = this.datapoints.reverse().map((p, ind) => ({
        ...p,
        ind: ind + 1,
        date: moment(p.date).format("DD-MM-YYYY HH:mm"),
        status: this.calibDataSeverity(p),
      }));
    },
    async getEpisodes() {
      this.loading = true;
      if (!this.episodes) {
        this.loading = false;
        return;
      }
      this.episodes = this.episodes.map((e) => ({
        ...e,
        title: e.name,
        content: `
            <span class="mt-2" style="font-size:0.85rem;">
              ${this.$t("common.date")} ${moment(e.date).format(
          "DD-MM-YYYY HH:mm"
        )}
            </span>
            `,
        message: `
            <i aria-hidden="true" class="v-icon notranslate mdi mdi-gauge theme--light" style="font-size: 0.9rem;"></i>
            <span style="font-size:0.8rem;">
              ${e.config && e.config.maxInflatePressure}
            </span>
            <span style="font-size:0.8rem;">mmHg</span>
            <i aria-hidden="true" class="ml-2 v-icon notranslate mdi mdi-repeat theme--light" style="font-size: 0.9rem;"></i>
            <span style="font-size:0.8rem;">
              ${e.config && e.config.calibsPerHour} ${this.$t("common.times")}
            </span>
            <span style="font-size:0.8rem;">/h</span>
            `,
        image: false,
        item: e,
      }));
      // .reverse();

      this.items = this.episodes;
      this.loading = false;
    },
    async renderStatsPieChart() {
      this.chartPieOptions = {
        ...statsPieChartOptions,
        series: [
          {
            type: "pie",
            name: this.$t("common.bloodPressure"),
            data: [
              {
                name: this.$t("common.low"),
                y: this.stats.low.percent,
              },
              {
                name: this.$t("common.normal"),
                y: this.stats.normal.percent,
                sliced: true,
                selected: true,
              },
              {
                name: this.$t("common.high"),
                y: this.stats.high.percent,
                sliced: true,
                selected: false,
              },
              {
                name: this.$t("common.veryHigh"),
                y: this.stats.veryHigh.percent,
              },
            ],
          },
        ],
      };
    },
    async renderHeatmapChart() {
      var data = [];
      heatmapChartOptions.yAxis.categories = [];
      for (var i = 0; i <= 23; i++) {
        var hour = i < 13 ? i : i - 12;
        heatmapChartOptions.yAxis.categories.push(
          `${hour < 10 ? "0" : ""}${hour} ${i < 13 ? "am" : "pm"}`
        );
        for (var j = 0; j <= 6; j++) {
          var points = this.datapoints.filter(
            (p) => moment(p.date).day() == j && moment(p.date).hour() == i
          );
          if (points.length) {
            data.push([j, i, this.calibAverage(points, "sys")]);
          } else {
            // data.push([j, i, 120]);
          }
        }
      }
      this.chartHeatmapOptions = {
        ...heatmapChartOptions,
        series: [
          {
            ...heatmapChartOptions.series[0],
            data,
          },
        ],
      };
    },
    async renderStatsLineChart() {
      var pulses = [];
      var ranges = [];
      var dates = [];
      for (var i = 0; i < this.datapoints.length; i++) {
        var point = this.datapoints[i] || {};
        var { pulse, sys, dia, date } = point;
        if (!pulse || !sys || !dia || !date) continue;
        pulses.push([i, pulse]);
        ranges.push([i, sys, dia]);
        dates.push([i, moment(date).valueOf()]);
      }

      this.chartLineOptions = {
        ...statsLineChartOptions,
        series: [
          {
            name: this.$t("common.heartRate"),
            data: pulses,
            type: "spline",
            zIndex: 2,
            color: "#F06292",
            marker: {
              // radius: 1,
              fillColor: "#e0e0e0",
              lineWidth: 1,
              lineColor: "#EC407A",
            },
          },
          {
            name: this.$t("common.bloodPressure"),
            data: ranges,
            type: "arearange",
            lineWidth: 0,
            linkedTo: ":previous",
            color: "#E3F2FD",
            fillOpacity: 1,
            zIndex: 1,
            marker: {
              // radius: 1,
              fillColor: "#e0e0e0",
              lineWidth: 1,
              lineColor: "#039BE5",
              symbol: "circle",
              // enabled: false,
            },
          },
          {
            name: this.$t("common.date"),
            data: dates,
            type: "spline",
            zIndex: 0,
            // visible: false,
          },
        ],
      };
    },
    async renderBubbleChart() {
      var data = this.datapoints.map((i, ind) => ({
        ind,
        ...i,
        x: moment(i.date).day(),
        y: moment(i.date).hour(),
        z: Math.abs(i.sys),
        color: this.getDataSeverityColor(i),
        opacity: 1,
        marker: {
          lineWidth: 0,
          lineColor: null,
        },
      }));
      this.chartBubbleOptions = {
        ...bubbleChartOptions,
        series: [
          {
            data,
            marker: {
              fillOpacity: 0.6,
            },
            events: {
              click: (e) => {
                var p = e.point;
                var textString = `${this.$t("common.bloodPressure")}: ${
                  p.sys
                } - ${p.dia}, ${p.pulse} bpm\n${this.$t(
                  "common.datetime"
                )}: ${moment(p.date).format("HH:mm DD/MM/YYYY")}`;
                alert(textString);
              },
            },
          },
        ],
      };
    },
    async calculateAvg() {
      this.avg = {
        pulse: this.calibAverage(this.datapoints, "pulse"),
        sys: this.calibAverage(this.datapoints, "sys"),
        dia: this.calibAverage(this.datapoints, "dia"),
      };
    },
    async calculateStats() {
      var points = JSON.parse(JSON.stringify(this.datapoints));
      var lowPoints = points.filter((p) => p.sys < 90);
      var normPoints = points.filter((p) => 90 <= p.sys && p.sys < 130);
      var highPoints = this.datapoints.filter(
        (p) => 130 <= p.sys && p.sys < 160
      );
      var veryHighPoints = points.filter((p) => p.sys >= 160);

      this.stats = {
        low: {
          count: lowPoints.length,
          percent: Math.floor(
            (lowPoints.length / this.datapoints.length) * 100
          ),
          pulse: this.calibAverage(lowPoints, "pulse"),
          sys: this.calibAverage(lowPoints, "sys"),
          dia: this.calibAverage(lowPoints, "dia"),
        },
        normal: {
          count: normPoints.length,
          percent: Math.floor(
            (normPoints.length / this.datapoints.length) * 100
          ),
          pulse: this.calibAverage(normPoints, "pulse"),
          sys: this.calibAverage(normPoints, "sys"),
          dia: this.calibAverage(normPoints, "dia"),
        },
        high: {
          count: highPoints.length,
          percent: Math.floor(
            (highPoints.length / this.datapoints.length) * 100
          ),
          pulse: this.calibAverage(highPoints, "pulse"),
          sys: this.calibAverage(highPoints, "sys"),
          dia: this.calibAverage(highPoints, "dia"),
        },
        veryHigh: {
          count: veryHighPoints.length,
          percent: Math.floor(
            (veryHighPoints.length / this.datapoints.length) * 100
          ),
          pulse: this.calibAverage(veryHighPoints, "pulse"),
          sys: this.calibAverage(veryHighPoints, "sys"),
          dia: this.calibAverage(veryHighPoints, "dia"),
        },
      };
    },
    calibAverage(points = [], field = "pulse") {
      var sum = 0;
      var count = 0;
      for (var point of points) {
        if (point[field]) {
          sum += point[field];
          count += 1;
        }
      }
      if (!count) return 0;
      return Math.floor(sum / count);
    },
    view(item) {
      this.$emit("view", item);
      this.$router.push(`/history/${item.id}`);
    },
  },
};
</script>

<style lang="scss">
.search_patient {
  background-color: #313959;
  border-radius: 12px !important;
  label,
  input {
    margin-top: 0px !important;
    color: #e0e0e0 !important;
  }
}
</style>
